<template>
    <div class="card-tail">
        <div>
            <el-form>
                <div class="p-5">
                    <header class="text-header-blue text-xl font-semibold mb-8">Redovisningsprinciper</header>
                    <div v-if="accountingPrinciplesData" v-loading="$waiting.is('loading')">
                        <div v-for="(card, index) in accountingPrinciplesData.cards" :key="index">
                            <AccountingPrincipleCards
                                :annual-report-id="annualReportId"
                                :card-data="card"
                                :last-index="accountingPrinciplesData.cards.length"
                                :accounting-principles-data="accountingPrinciplesData"
                                :index="index"
                                @move-up="moveUp"
                                @move-down="moveDown"
                                @saveData="saveData()"
                                @close="closeCard"
                            />
                        </div>
                    </div>
                </div>

                <div class="p-5 flex justify-end w-full">
                    <el-button size="medium" type="primary" @click="goToPageBefore">Tillbaka</el-button>
                    <el-button size="medium" type="warning">Utkast Årsredovisning</el-button>
                    <el-button size="medium" class="button is-info" @click="showDraft">Granska</el-button>
                    <el-button size="medium" type="primary" @click="goToNextPage">Nästa</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import Api from "../annualReport.api";
export default {
    props: {
        clientInfo: {
            type: Object,
        },
    },
    components: {
        AccountingPrincipleCards: () => import("./components/AccountingPrincipleCards.vue"),
    },
    data() {
        return {
            annualReportId: undefined,
            draftVisible: false,
            accountingPrinciplesData: {},
            oldInputText: "",
            addNewTextCard: false,
        };
    },
    async created() {
        this.$waiting.start("loading");
        this.annualReportId = this.$route.params.annualReportId;
        this.accountingPrinciplesData = await Api.getAccountingPrincipleDetails(this.annualReportId);
        // this.setOldValues();
        this.$waiting.end("loading");
    },
    methods: {
        // setOldValues() {
        //     this.oldInputText = this.annualReportData.generalBusinessInfo;
        // },
        async goToNextPage() {
            await this.$router.push(`/annualReport/notes/${this.annualReportId}`);
        },
        async goToPageBefore() {
            await this.$router.push(`/annualReport/balanceSheet/${this.annualReportId}`);
        },
        showDraft() {
            this.draftVisible = true;
            // this.$nextTick(() => {
            //     this.$refs.inspectManagementReportModal.$el.focus();
            // });
        },
        async saveData() {
            await Api.updateAccountingPrincipleDetails(this.annualReportId, this.accountingPrinciplesData);
        },
        async closeCard(value = {}) {
            //this.$waiting.start(value.load);
            console.log("closeCard", value);
            this.accountingPrinciplesData = await Api.getAccountingPrincipleDetails(this.annualReportId);
            //this.$waiting.end(value.load);
        },
        toggleNewTextCard() {
            this.addNewTextCard = this.addNewTextCard == false ? true : false;
        },
        moveUp(value) {
            if (value.index > 0) {
                const cardToMoveUp = this.accountingPrinciplesData.cards[value.index];
                this.accountingPrinciplesData.cards.splice(value.index, 1);
                this.accountingPrinciplesData.cards.splice(value.index - 1, 0, cardToMoveUp);
            } else if (value.index === 0) {
                this.$notify.error("Det går inte att flytta upp, detta är första kortet");
            }
        },
        moveDown(value) {
            const lastIndex = this.accountingPrinciplesData.cards.length - 1;
            if (value.index < lastIndex) {
                const cardToMoveDown = this.accountingPrinciplesData.cards[value.index];
                this.accountingPrinciplesData.cards.splice(value.index, 1);
                this.accountingPrinciplesData.cards.splice(value.index + 1, 0, cardToMoveDown);
            } else if (value.index === lastIndex) {
                this.$notify.error("Det går inte att flytta ner, detta är sista kortet");
            }
        },
    },
};
</script>

<style>
.custom-textarea .el-textarea__inner:disabled {
    color: #444a55;
    border-color: #aaaaaa;
    background-color: #eeeeee;
    font-size: 14px;
}
.custom-textarea {
    font-size: 14px;
}
.round-button,
.round-button:active,
.round-button:focus {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #415770;
    font-size: x-large;
}
.round-button:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #6e85a0;
    font-size: x-large;
}
.save-button {
    background-color: #48c78e;
    border-color: #48c78e;
    color: #ffffff;
}
.save-button:hover {
    background-color: #5bd6a3;
    color: #ffffff;
}
.tooltip-content {
    width: 500px;
    padding: 5px;
    border: none;
}
.management-report-card-box {
    margin-top: 40px;
    max-width: fit-content;
    min-width: 800px;
    overflow: auto;
}
</style>
